.page {
  margin: 20px;
}
.interactions {
  text-align: center;
}
.table {
  margin: 20px 0;
}
.table-header {
  line-height: 24px;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff;
}
.table-header-border {
  border: 1px solid #e3e3e3;
}

.table-empty { margin: 200px; text-align: center; font-size: 16px;
}
.table-row {
  margin-top: 10px;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
}
.rowPaddingVertical20 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.rowPaddingVertical10 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.button-inline {
  border-width: 0;
  background: transparent;
  color: inherit;
  text-align: inherit; -webkit-font-smoothing: inherit; padding: 0;
  font-size: inherit;
  cursor: pointer;
}
.button-active {
  border-radius: 0;
  border-bottom: 1px solid #38BB6C;
}

.button-config {
  border-width: 0;
  background: transparent;
  color: inherit;
  text-align: inherit; -webkit-font-smoothing: inherit; padding: 0;
  font-size: inherit;
  cursor: pointer;
  background-image: url("/settings-25.png");
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  float: right;
}

.button-submit {
  font-weight: bold;
  color:inherit;
  border: 2px solid #4d90fe;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
}
.button-submit:hover {
  border: 2px solid #4d90fe;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  background-color: #f8f8f8;
  color: #222;
}
.button-submit:focus {
  border: 2px solid #4d90fe;
  outline: none;
}

.button-submit-disabled {
  color: grey;
  border: 2px solid #FF0000;
}
.button-submit-disabled:hover {
  color: grey;
  border: 2px solid #FF0000;
}

input[type="text"]:disabled {
  background: #dddddd;
}

.descriptionText-disabled {
  color: grey;
}

.pageNo {
  text-align:center;
  margin-bottom: 10px;
  padding-top: 10px;
}

.titleSubjectCol {
  height: 80px;
  overflow:hidden;
}

.img {
  width:70px;
  max-height:70px;
}

.imgCol {
  height: 80px;
}

.inputBoxSmall {
  font-size:13px;
  padding: 7px;
}

.inputTextDescription {
  font-size:13px;
}

.lowImpactFont {
  font-size: 12px;
  color: grey;
}

.bold {
  font-weight: bold;
}

#source a:link { text-decoration: underline; color : grey; }

.quickLinkButton {
  font-weight: bold;
  color:inherit;
  background: #ffffff;
  padding: 0px 15px;
  margin: 0px 0px;
  font: inherit;
}
.quickLinkButton:hover {
  border: 1px solid #c6c6c6;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  background-color: #f8f8f8;
  color: #222;
}
.quickLinkButton:focus {
  border: 1px solid #4d90fe;
  outline: none;
}


/*
[class*="col"] {
  background: #EEF;
  border-style: solid;
  border-width: 1px;
  border-color: red;
}


.divId {
  background: pink;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
*/